export default {
  props: {
    initialIndex: {
      type: Number,
      required: false,
      default: 3,
    },
    perPage: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      isActiveButton: false,
      activeButtonTimer: null,
    }
  },
  computed: {
    displayableItems() {
      return this.allItems
    },
    maxDisplayableIndex() {
      return this.displayableItems?.length
    },
    hasDisplayableItems() {
      return this.maxDisplayableIndex > 0
    },
    currentItems() {
      return this.displayableItems?.filter((_item, index) => index <= this.currentIndex)
    },
    canIncrementIndex() {
      return this.currentIndex < this.maxDisplayableIndex
    },
    buttonStatusClass() {
      return { 'is-active': this.isActiveButton }
    },
  },
  methods: {
    onClickLoadButton() {
      this.incrementIndex()
      this.activateButton()
    },
    incrementIndex() {
      const newIndex = this.currentIndex + this.perPage
      this.currentIndex = newIndex < this.maxDisplayableIndex ? newIndex : this.maxDisplayableIndex
    },
    activateButton() {
      this.isActiveButton = false
      if (this.activeButtonTimer) clearTimeout(this.activeButtonTimer)
      this.$nextTick(() => {
        this.isActiveButton = true
        this.activeButtonTimer = setTimeout(() => {
          this.isActiveButton = false
          this.activeButtonTimer = false
        }, 400)
      })
    },
  },
}
