<template>
  <section class="NewsTable">
    <h3 class="NewsTable-title">
      {{ title }}
    </h3>

    <transition-group tag="ul" class="NewsTable-list">
      <NewsItem
        v-for="(item, index) in currentItems"
        :key="item.key"
        :item="item"
        :class="getDelayClass(index, initialIndex, perPage)"
      />
    </transition-group>

    <p v-if="canIncrementIndex" class="NewsTable-loadButtonWrapper">
      <a
        href="#"
        class="NewsTable-loadButton"
        :class="buttonStatusClass"
        @click.prevent="onClickLoadButton()"
        >さらに読み込む</a
      >
    </p>
  </section>
</template>

<script>
import NewsItem from './NewsItem'

import IncrementList from './mixins/IncrementList'

import getDelayClass from './functions/getDelayClass'

import 'common/icon-reload.svg'

export default {
  name: 'NewsTable',
  components: {
    NewsItem,
  },
  mixins: [IncrementList],
  /**
   * @typedef {Object.<string, Array>} Props
   * @property {string} title - ニュースの分類名
   * @property {Object[]} allItems - ニュース情報のリスト
   */
  props: {
    title: {
      type: String,
      required: true,
    },
    allItems: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  /**
   * @typedef {Object.<number>} Data
   * @property {number} currentIndex - 現在表示されている末尾要素のindex
   */
  data() {
    return {
      currentIndex: this.initialIndex,
    }
  },
  methods: {
    getDelayClass,
  },
}
</script>

<style lang="scss" scoped>
@use "./css/foundation/vars";
@use "./css/foundation/mixins";

.NewsTable {
  margin-top: 8px;

  & + & {
    margin-top: 20px;
  }
}

.NewsTable-title {
  padding-left: 5px;
  font-size: 1.4rem;
  font-weight: bold;
}

.NewsTable-list {
  display: block;
  margin-top: 8px;
}

.NewsTable-loadButtonWrapper {
  padding: 2px 19px;
  margin-top: 3px;
  background: vars.$color-bg-1;
  border-radius: 0 0 0 4px;
}

.NewsTable-loadButton {
  @include mixins.reloadIcon('~common/icon-reload-fff.svg');
  position: relative;
  display: inline-block;
  padding: 4px 4px 4px 25px;
  font-size: 1.3rem;
  font-weight: vars.$font-weight-medium;
  color: vars.$color-text;
}
</style>
