<template>
  <div id="news_list" class="NewsList">
    <div class="contentHeading">
      <h2 class="contentHeading-title">ニュース・リリース</h2>
    </div>

    <NewsTable
      v-for="(list, index) in lists"
      :key="index"
      :title="list.title"
      :all-items="list.allItems"
      :initial-index="3"
      :picked-up-index="-1"
      :per-page="8"
    />
  </div>
</template>

<script>
import NewsTable from './NewsTable.vue'

export default {
  components: { NewsTable },
  /**
   * @typedef {Object.<Array>} Data
   * @property {Object[]} lists - ニュースのリスト（分類済）
   */
  data() {
    return {
      lists: [
        {
          title: '業界紙',
          allItems: gon.electricity_news_items,
        },
        {
          title: '一般紙',
          allItems: gon.general_news_items,
        },
        {
          title: '官公庁発表',
          allItems: gon.government_news_items,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import './css/vue_modules/content_heading';

.NewsList {
  margin-top: 50px;
}
</style>
