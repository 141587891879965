<template>
  <li class="NewsItem" :class="ribbonClass">
    <p class="NewsItem-title">
      <a :href="item.link" target="_blank" rel="noopener" class="NewsItem-titleLink"
        >{{ item.title }} {{ item.description }}</a
      >
    </p>
    <p class="NewsItem-info">{{ item.media_name }} {{ item.channel_title }} {{ item.pub_date }}</p>
  </li>
</template>

<script>
export default {
  name: 'NewsItem',
  /**
   * @typedef {Object.<Object>} Props
   * @property {Object} item - ニュース情報
   */
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * メディア名からリボンの色を指定するclassを生成
     * @returns {string}
     */
    ribbonClass() {
      return `t-${this.item.media_slug}`
    },
  },
}
</script>

<style lang="scss" scoped>
@use "sass:color";
@use "sass:map";
@use "./css/foundation/vars";
@use "./css/foundation/mixins";

@include mixins.fadeInUp(9);

.NewsItem {
  position: relative;
  display: block;
  padding: 9px 0.5em 8px 19px;
  background: vars.$color-bg-1;

  & + & {
    margin-top: 3px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 5px;
    height: 100%;
    content: '';
    background: map.get(vars.$colors-media, 'default');
  }

  @each $key, $val in vars.$colors-media {
    &.t-#{$key}::before {
      background: $val;
    }
  }
}

.NewsItem-title {
  font-size: 1.6rem;
}

.NewsItem-titleLink {
  @include mixins.triangleLeft();
  position: relative;
  display: block;
  padding-left: 17px;
  color: vars.$color-btn-accept;
  text-decoration: underline;
  transition: color 0.2s ease;

  &::after {
    top: 0.8rem;
    left: 0;
  }

  &:hover {
    color: color.scale(vars.$color-btn-accept, $lightness: 30%);

    &::after {
      transform: translateX(4px) rotate(90deg);
    }
  }
}

.NewsItem-info {
  margin-top: 3px;
  font-size: 1.3rem;
  font-weight: vars.$font-weight-medium;
}
</style>
